import createSagaMiddleware from '@redux-saga/core'
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import {
  promocodeDataSaga,
  promocodeSlice,
} from '../features/purchase-promocode'
import {
  loginUserViaGoogleSaga,
  loginUserViaMetamaskSaga,
} from '../features/auth/entity/authorizationSaga'
import authorizationSlice from '../features/auth/entity/authorizationSlice'
import { categorySaga } from './CategoryState/categorySaga'
import categorySlice from './CategoryState/categorySlice'
import filterSlice from './FilterState/filterSlice'
import {
  freeWhiteListCountSaga,
  freeWhiteListSaga,
} from './FreeWhiteListsState/freeWhiteListSaga'
import freeWhiteListSlice from './FreeWhiteListsState/freeWhiteListSlice'
import { launchpadSaga } from './LaunchpadState/launchpadSaga'
import launchpadSlice from './LaunchpadState/launchpadSlice'
import { networkSaga } from './NetworkState/networkSaga'
import networkSlice from './NetworkState/networkSlice'
import {
  cancelOrderByIdSaga,
  completedOrderSaga,
  orderByIdSaga,
  ordersCountSaga,
  ordersSaga,
  patchOrderDataByIdSaga,
  postOrderRefreshByIdSaga,
  postOrderSaga,
} from './OrderState/orderSaga'
import orderSlice from './OrderState/orderSlice'
import {
  featuredProjectBannersSaga,
  projectsBySlugSaga,
  projectsCountSaga,
  projectsSaga,
} from './ProjectState/projectSaga'
import projectSlice from './ProjectState/projectSlice'
import { saleTypesSaga } from './SaleTypeState/saleTypeSaga'
import saleTypesSlice from './SaleTypeState/saleTypeSlice'
import {
  changeUserNameSaga,
  getPaymentWalletSaga,
  getUserSaga,
} from './UserState/userSaga'
import userSlice from './UserState/userSlice'
import {
  bestOfferWhiteListsSaga,
  whiteListByIdSaga,
  whiteListsCountSaga,
  whiteListsPriceRangeSaga,
  whiteListsSaga,
} from './WhiteListState/whiteListSaga'
import whiteListSlice from './WhiteListState/whiteListSlice'
import loadingReducer from './LoadingState/loadingSlice'
import loadingMiddleware from './middleware'

const saga = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    authorization: authorizationSlice,
    user: userSlice,
    saleTypes: saleTypesSlice,
    freeWhiteList: freeWhiteListSlice,
    network: networkSlice,
    launchpad: launchpadSlice,
    category: categorySlice,
    whiteList: whiteListSlice,
    project: projectSlice,
    order: orderSlice,
    filter: filterSlice,
    promocode: promocodeSlice,
    loading: loadingReducer,
  },
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware().concat(loadingMiddleware, saga),
})
saga.run(loginUserViaGoogleSaga)
saga.run(loginUserViaMetamaskSaga)
saga.run(getUserSaga)
saga.run(getPaymentWalletSaga)
saga.run(changeUserNameSaga)
saga.run(saleTypesSaga)
saga.run(freeWhiteListSaga)
saga.run(freeWhiteListCountSaga)
saga.run(networkSaga)
saga.run(launchpadSaga)
saga.run(categorySaga)
saga.run(whiteListsSaga)
saga.run(bestOfferWhiteListsSaga)
saga.run(whiteListByIdSaga)
saga.run(whiteListsCountSaga)
saga.run(whiteListsPriceRangeSaga)
saga.run(featuredProjectBannersSaga)
saga.run(projectsSaga)
saga.run(projectsCountSaga)
saga.run(projectsBySlugSaga)
saga.run(ordersSaga)
saga.run(postOrderSaga)
saga.run(completedOrderSaga)
saga.run(postOrderRefreshByIdSaga)
saga.run(orderByIdSaga)
saga.run(cancelOrderByIdSaga)
saga.run(patchOrderDataByIdSaga)
saga.run(ordersCountSaga)
saga.run(promocodeDataSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
