import { call, put, takeLeading, takeLatest } from 'redux-saga/effects'
import {
  getWhiteListById,
  getWhiteLists,
  getWhiteListsCount,
  getWhiteListsPriceRange,
} from '../../API/apiURL'
import {
  getWhiteListDataById,
  getWhiteListPriceRangeData,
  getWhiteListsCountDataSuccess,
  getWhiteListsDataSuccess,
  getWhiteListsDataError,
  getBestOfferWhiteListData,
  errorGettingWhiteListData,
  errorGettingWhiteListsCountData,
  errorGettingWhiteListBestOfferData,
  errorGettingWhiteListsPriceRangeData,
} from './whiteListSlice'

function* whiteListByIdFetch({ payload }) {
  try {
    const result = yield call(() => getWhiteListById(payload))
    yield put(getWhiteListDataById(result.data))
  } catch (_error) {
    yield put(errorGettingWhiteListData('Failed to get WhiteList.'))
  }
}
function* whiteListsDataFetch({ payload }) {
  const initFetching = payload.offset === 0

  try {
    const result = yield call(() => getWhiteLists(payload))
    yield put(getWhiteListsDataSuccess({ data: result.data, initFetching }))
  } catch (_error) {
    yield put(getWhiteListsDataError('Failed to get WhiteLists.'))
  }
}
function* bestOfferWhiteListDataFetch({ payload }) {
  try {
    const result = yield call(() => getWhiteLists(payload))
    yield put(getBestOfferWhiteListData(result.data))
  } catch (_error) {
    yield put(errorGettingWhiteListBestOfferData('Failed to get best offer.'))
  }
}
function* whiteListsCountFetch({ payload }) {
  try {
    const result = yield call(() => getWhiteListsCount(payload))
    yield put(getWhiteListsCountDataSuccess(result.data))
  } catch (_error) {
    yield put(
      errorGettingWhiteListsCountData('Failed to get WhiteLists count.'),
    )
  }
}
function* whiteListsPriceRangeFetch() {
  try {
    const result = yield call(() => getWhiteListsPriceRange())
    yield put(getWhiteListPriceRangeData(result.data))
  } catch (_error) {
    yield put(
      errorGettingWhiteListsPriceRangeData('Failed to get price range.'),
    )
  }
}

export function* whiteListByIdSaga() {
  yield takeLeading('whiteListSlice/getWhiteListDataById', whiteListByIdFetch)
}

export function* whiteListsSaga() {
  yield takeLatest('whiteListSlice/getWhiteListsData', whiteListsDataFetch)
}
export function* bestOfferWhiteListsSaga() {
  yield takeLeading(
    'whiteListSlice/getBestOfferWhiteListData',
    bestOfferWhiteListDataFetch,
  )
}
export function* whiteListsCountSaga() {
  yield takeLeading(
    'whiteListSlice/getWhiteListsCountData',
    whiteListsCountFetch,
  )
}

export function* whiteListsPriceRangeSaga() {
  yield takeLeading(
    'whiteListSlice/getWhiteListPriceRangeData',
    whiteListsPriceRangeFetch,
  )
}
