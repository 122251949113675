import { CookiesProvider } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'

import App from './App/App'
import { Web3Providers } from './App/web3-providers'
import { store } from './Redux/store'

import './index.module.scss'

const container = document.getElementById('root')!
const root = createRoot(container)

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="/*" element={<App />} />),
)

root.render(
  <Provider store={store}>
    <CookiesProvider>
      <Web3Providers>
        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      </Web3Providers>
    </CookiesProvider>
  </Provider>,
)
