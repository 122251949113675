import tooltipStyles from './tooltipStyles.module.scss'

interface Props {
  text: string
  title?: string
  classNamesObj: any
}

export const Tooltip = (props: Props) => {
  const { text, title, classNamesObj } = props

  return (
    <div
      className={
        tooltipStyles.tooltipContainer +
        ' ' +
        classNamesObj?.customTooltipContainer
      }
    >
      {title && <strong className={tooltipStyles.tooltipTitle}>{title}</strong>}
      <p className={tooltipStyles.tooltipContent}>{text}</p>
    </div>
  )
}
