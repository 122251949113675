import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isLoader: false,
  isLoaderMessage: false,
}

const loadingSlice = createSlice({
  name: 'loadingSlice',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setLoader: (state, { payload }) => {
      state.isLoader = payload
    },
    setLoadingAndLoader: (state, { payload }) => {
      state.isLoading = payload
      state.isLoader = payload
    },
    setIsLoaderMessage: (state, { payload }) => {
      state.isLoaderMessage = payload
    },
  },
})

export const {
  setLoading,
  setLoader,
  setLoadingAndLoader,
  setIsLoaderMessage,
} = loadingSlice.actions
export default loadingSlice.reducer
