import { checkHealthRequest } from '../API/apiURL'
import {
  setLoading,
  setLoader,
  setLoadingAndLoader,
  setIsLoaderMessage,
} from './LoadingState/loadingSlice'

const loadingMiddleware =
  (store: any) => (next: any) => async (action: { type: string }) => {
    if (action.type === 'CHECK_HEALTH') {
      let timeoutId: NodeJS.Timeout | undefined

      const cleanUp = () => {
        clearTimeout(timeoutId)
        store.dispatch(setLoadingAndLoader(false))
      }

      try {
        timeoutId = setTimeout(() => {
          store.dispatch(setLoader(true))
        }, 1000)
        store.dispatch(setLoading(true))

        const response = await checkHealthRequest()
        const { status, data } = response
        if (status === 200 && data.status === 'ok') {
          store.dispatch(setIsLoaderMessage(false))
          cleanUp()
        } else {
          store.dispatch(setIsLoaderMessage(true))
        }
      } catch (error) {
        store.dispatch(setIsLoaderMessage(true))
      }
    }
    return next(action)
  }

export default loadingMiddleware
