import style from './promocode.module.scss'

import DiscountIcon from '@mui/icons-material/Discount'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

import { Tooltip } from '../../../../Components/Tooltip'

const MIN_LENGTH = 6

interface PromocodeProps {
  onApply: (code: string) => void
  onFocus?: () => void
  initialValue?: string
  isError?: boolean
}

export const Promocode: FC<PromocodeProps> = ({
  onApply,
  onFocus,
  initialValue,
  isError,
}) => {
  const { register, handleSubmit, watch } = useForm()

  const onSubmit = (data: any) => {
    const code = data.promocode.trim()

    if (code) {
      onApply(code)
    }
  }

  const inputCodeValue = watch('promocode')

  return (
    <div className={style.promocode}>
      <p className={style.title}>Promocode</p>
      <form className={style.wrapperField} onSubmit={handleSubmit(onSubmit)}>
        <DiscountIcon className={style.userIcon} />
        <input
          {...register('promocode')}
          type="text"
          required={true}
          minLength={MIN_LENGTH}
          maxLength={16}
          placeholder="XASAQDHJ6"
          className={style.customInput}
          defaultValue={initialValue}
          onFocus={onFocus}
        />
        <button
          type="submit"
          className={style.applyBtn}
          disabled={!inputCodeValue || inputCodeValue.length < MIN_LENGTH}
        >
          Apply
        </button>
        <div className={style.helpIconWrap}>
          <HelpOutlineOutlinedIcon className={style.helpIcon} />
          <Tooltip
            text={`Use the promo code to get a discount on your lot. Minimum promo code length is ${MIN_LENGTH} symbols.`}
            classNamesObj={{ customTooltipContainer: style.customTooltip }}
          />
        </div>
      </form>
      {isError && (
        <span className={style.error}>
          Invalid promo code. This promo code doesn't exist.
        </span>
      )}
    </div>
  )
}
