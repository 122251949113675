import { lazy, Suspense, useEffect } from 'react'
import { ExtraErrorData as SentryExtraErrorData } from '@sentry/integrations'
import { init, BrowserTracing, Replay, captureException } from '@sentry/react'
import axios from 'axios'
import mixpanel from 'mixpanel-browser'
import { createPortal } from 'react-dom'
import { Route, Routes } from 'react-router-dom'

import { CookiesBar } from '../Components/CookiesBar/CookiesBar'
import { DearPlate } from '../Components/Plate/Plate'
import { routes } from '../routes'
import { RequireAuth } from '../Utils/requaireAuth'
import CustomLoader from '../Components/Loader'
import { useAppDispatch, useAppSelector } from '../Redux/hooks'
import {
  selectorIsLoader,
  selectorIsLoading,
} from '../Redux/LoadingState/loadingSelector'

const Account = lazy(() => import('../Pages/Account/Account'))
const HomePage = lazy(() => import('../Pages/HomePage/HomePage'))
const CookiesPage = lazy(() => import('../Pages/CookiesPage/CookiesPage'))
const FreeWhitelistsPage = lazy(
  () => import('../Pages/FreeWhitelists/FreeWhitelists'),
)
const NotFoundPage = lazy(() => import('../Pages/NotFoundPage/NotFoundPage'))
const PrivacyPage = lazy(() => import('../Pages/PrivacyPage/PrivacyPage'))
const ProjectPage = lazy(() => import('../Pages/Project/Project'))
const ProjectsPage = lazy(() => import('../Pages/ProjectsPage/ProjectsPage'))
const SearchResultsPage = lazy(
  () => import('../Pages/SearchResultPage/SearchResultPage'),
)
const SignInPage = lazy(() => import('../Pages/SignInPage/SignInPage'))
const Support = lazy(() => import('../Pages/Support/Support'))
const TermsPage = lazy(() => import('../Pages/TermsPage/TermsPage'))
const WatchlistPage = lazy(() => import('../Pages/WatchlistPage/WatchlistPage'))
const WhiteListsPage = lazy(
  () => import('../Pages/WhiteListsPage/WhiteListsPage'),
)
const Whitelist = lazy(() => import('../Pages/Whitelist/Whitelist'))

function App() {
  mixpanel.init('cb33c67fbd8ef8e8f3e129495f1f60a6', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  })

  const dispatch = useAppDispatch()

  const isLoading = useAppSelector(selectorIsLoading)
  const isLoader = useAppSelector(selectorIsLoader)

  const sentryDsn = process.env.REACT_APP_SENTRY_DSN
  const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT

  useEffect(() => {
    dispatch({ type: 'CHECK_HEALTH' })
  }, [dispatch])

  useEffect(() => {
    if (sentryDsn && sentryEnvironment) {
      init({
        dsn: sentryDsn,
        environment: sentryEnvironment,
        integrations: [
          new BrowserTracing(),
          new Replay(),
          new SentryExtraErrorData(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })

      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          captureException(error)
          return Promise.reject(error)
        },
      )
    }
  }, [])

  return isLoading ? (
    <CustomLoader isLoader={isLoader} />
  ) : (
    <>
      <DearPlate />
      <Routes>
        <Route
          path={routes.home}
          element={
            <Suspense fallback={null}>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path={routes.signIn}
          element={
            <Suspense fallback={null}>
              <SignInPage />
            </Suspense>
          }
        />
        <Route
          path={routes.whitelist}
          element={
            <Suspense fallback={null}>
              <Whitelist />
            </Suspense>
          }
        />
        <Route
          path={routes.projects}
          element={
            <Suspense fallback={null}>
              <ProjectsPage />
            </Suspense>
          }
        />
        <Route
          path={routes.project}
          element={
            <Suspense fallback={null}>
              <ProjectPage />
            </Suspense>
          }
        />
        <Route
          path={routes.support}
          element={
            <Suspense fallback={null}>
              <Support />
            </Suspense>
          }
        />
        <Route
          path={routes.freeWhitelists}
          element={
            <Suspense fallback={null}>
              <FreeWhitelistsPage />
            </Suspense>
          }
        />
        <Route
          path={routes.searchResults}
          element={
            <Suspense fallback={null}>
              <SearchResultsPage />
            </Suspense>
          }
        />
        <Route
          path={routes.account}
          element={
            <Suspense fallback={null}>
              <RequireAuth>
                <Account />
              </RequireAuth>
            </Suspense>
          }
        />
        <Route
          path={routes.whiteLists}
          element={
            <Suspense fallback={null}>
              <WhiteListsPage />
            </Suspense>
          }
        />
        <Route
          path={routes.watchlist}
          element={
            <Suspense fallback={null}>
              <WatchlistPage />
            </Suspense>
          }
        />
        <Route
          path={routes.privacy}
          element={
            <Suspense fallback={null}>
              <PrivacyPage />
            </Suspense>
          }
        />
        <Route
          path={routes.terms}
          element={
            <Suspense fallback={null}>
              <TermsPage />
            </Suspense>
          }
        />
        <Route
          path={routes.cookies}
          element={
            <Suspense fallback={null}>
              <CookiesPage />
            </Suspense>
          }
        />
        <Route
          path={routes.errorPage}
          element={
            <Suspense fallback={null}>
              <NotFoundPage />
            </Suspense>
          }
        />
      </Routes>
      {createPortal(<CookiesBar />, document.body)}
    </>
  )
}

export default App
