import { call, put, takeLeading } from 'redux-saga/effects'
import {
  getCompletedOrder,
  getOrderById,
  getOrders,
  getOrdersCount,
  patchOrderById,
  postOrderCancelById,
  postOrderRefreshById,
  postOrders,
} from '../../API/apiURL'
import {
  errorGettingOrderData,
  errorGettingOrdersCountData,
  errorGettingOrdersData,
  errorGettingWallet,
  getCompletedOrderData,
  getOrderDataById,
  getOrdersCountData,
  getOrdersData,
  patchOrderDataById,
  postCancelOrder,
  postOrderData,
  postOrderRefreshByIdData,
} from './orderSlice'

function* patchOrderDataByIdFetch({ payload }) {
  try {
    const result = yield call(() => patchOrderById(payload))
    yield put(patchOrderDataById(result.data))
  } catch (_error) {
    yield put(errorGettingOrderData('Failed to get order.'))
  }
}
function* ordersFetch({ payload }) {
  try {
    const result = yield call(() => getOrders(payload))
    yield put(getOrdersData(result.data))
  } catch (_error) {
    yield put(errorGettingOrdersData('Failed to get orders.'))
  }
}
function* ordersCountFetch() {
  try {
    const result = yield call(() => getOrdersCount())
    yield put(getOrdersCountData(result.data))
  } catch (_error) {
    yield put(errorGettingOrdersCountData('Failed to get orders count.'))
  }
}
function* postOrderFetch({ payload: { successCb, ...orderData } }) {
  try {
    const result = yield call(() => postOrders(orderData))
    if (orderData.paymentMethod === 'whitepay_crypto') {
      window.location.href = result.data.acquiringUrl
    }

    yield put(postOrderData(result.data ?? {}))
    successCb?.()
  } catch (_error) {
    yield put(errorGettingOrderData('Failed to create order.'))
  }
}
function* completedOrderFetch({ payload }) {
  try {
    const result = yield call(() => getCompletedOrder(payload))
    yield put(getCompletedOrderData(result.data))
  } catch (_error) {
    yield put(errorGettingWallet('Failed to get completed order information.'))
  }
}
function* postOrderRefreshByIdFetch({ payload }) {
  try {
    const result = yield call(() => postOrderRefreshById(payload))
    yield put(postOrderRefreshByIdData(result.data))
  } catch (_error) {
    yield put(errorGettingOrderData('Failed to refresh order.'))
  }
}
function* orderByIdFetch({ payload }) {
  try {
    const result = yield call(() => getOrderById(payload))
    yield put(getOrderDataById(result.data))
  } catch (_error) {
    yield put(errorGettingOrderData('Failed to get order by id.'))
  }
}
function* cancelOrderFetch({ payload }) {
  try {
    const result = yield call(() => postOrderCancelById(payload.id))
    yield put(postCancelOrder(result.data))
  } catch (_error) {
    yield put(errorGettingOrderData('Failed to cancel order.'))
  }
}

export function* ordersSaga() {
  yield takeLeading('orderSlice/getOrdersData', ordersFetch)
}
export function* ordersCountSaga() {
  yield takeLeading('orderSlice/getOrdersCountData', ordersCountFetch)
}
export function* postOrderSaga() {
  yield takeLeading('orderSlice/postOrderData', postOrderFetch)
}
export function* completedOrderSaga() {
  yield takeLeading('orderSlice/getCompletedOrderData', completedOrderFetch)
}
export function* postOrderRefreshByIdSaga() {
  yield takeLeading(
    'orderSlice/postOrderRefreshByIdData',
    postOrderRefreshByIdFetch,
  )
}
export function* orderByIdSaga() {
  yield takeLeading('orderSlice/getOrderDataById', orderByIdFetch)
}
export function* cancelOrderByIdSaga() {
  yield takeLeading('orderSlice/postCancelOrder', cancelOrderFetch)
}
export function* patchOrderDataByIdSaga() {
  yield takeLeading('orderSlice/patchOrderDataById', patchOrderDataByIdFetch)
}
