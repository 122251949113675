import { createSlice } from '@reduxjs/toolkit'

interface InitialStateProps {
  code: string
  discount: number // in percents
  error: string
}

const initialState: InitialStateProps = {
  code: '',
  discount: 0,
  error: '',
}

const promocodeSlice = createSlice({
  name: 'promocodeSlice',
  initialState,
  reducers: {
    getPromocodeData: (state, { payload }) => {
      const { code, discountPercents } = payload

      state.code = code ?? initialState.code
      state.discount = discountPercents ?? initialState.discount
    },
    resetPromocode: () => ({ ...initialState }),

    setPromocodeError: (_, { payload }) => ({
      ...initialState,
      error: payload,
    }),
    resetPromocodeError: (state) => {
      state.error = initialState.error
    },
  },
})

export const {
  getPromocodeData,
  resetPromocode,
  setPromocodeError,
  resetPromocodeError,
} = promocodeSlice.actions

export default promocodeSlice.reducer
