import { useEffect } from 'react'
import clsx from 'clsx'

import LoaderStyles from './loader.module.scss'
import { useAppDispatch, useAppSelector } from '../../Redux/hooks'
import { setIsLoaderMessage } from '../../Redux/LoadingState/loadingSlice'
import { selectorIsLoaderMessage } from '../../Redux/LoadingState/loadingSelector'

const CustomLoader = ({ isLoader = true }: { isLoader: boolean }) => {
  const MESSAGE_TIMEOUT = Number(process.env.API_MESSAGE_TIMEOUT) || 10000

  const dispatch = useAppDispatch()
  const isLoaderMessage = useAppSelector(selectorIsLoaderMessage)

  useEffect(() => {
    const timoutId = setTimeout(() => {
      dispatch(setIsLoaderMessage(true))
    }, MESSAGE_TIMEOUT)

    return () => clearTimeout(timoutId)
  }, [])

  return (
    <div className={LoaderStyles.loaderWrapper}>
      <div
        className={clsx(
          LoaderStyles.loaderContainer,
          isLoader && LoaderStyles.loaderContainerVisible,
        )}
      >
        <div className={LoaderStyles.loader}>
          <div className={LoaderStyles.loaderCorner} />
        </div>
      </div>
      <p
        className={clsx(
          LoaderStyles.loaderMessage,
          isLoaderMessage && LoaderStyles.loaderMessageVisible,
        )}
      >
        {
          'We are updating our app. This may take a little longer than expected.\nPlease check back in a minute.'
        }
      </p>
    </div>
  )
}

export default CustomLoader
