import { createSlice } from '@reduxjs/toolkit'
import type {
  FeaturedProjectsBannersResponse,
  ProjectsBySlugResponse,
  ProjectsResponse,
} from '../../API/apiInterfaces'

interface InitialStateProps {
  featuredProjectBanners?: FeaturedProjectsBannersResponse[]
  projectsFilterValue: {
    of?: number
    st?: number[]
    n?: number[]
    l?: number[]
    c?: number[]
    sq?: string
    ow?: number
    sortBy?: string
    sortOrder?: string
    projectId?: number[]
    priceMin?: number
    priceMax?: number
    limit: number
    offset: number
  }
  projects: ProjectsResponse[]
  projectsBySlug?: ProjectsBySlugResponse
  projectsCounter: number
  featuredProjectDataError: string | undefined
  projectsDataError: string | undefined
  projectDataError: string | undefined
  projectsCountDataError: string | undefined
  showSaleTime: boolean
}

const initialState: InitialStateProps = {
  featuredProjectBanners: [],
  projects: [],
  projectsFilterValue: {
    sq: undefined,
    projectId: undefined,
    c: undefined,
    l: undefined,
    n: undefined,
    st: undefined,
    ow: undefined,
    sortBy: 'createdAt',
    sortOrder: 'DESC',
    priceMin: undefined,
    priceMax: undefined,
    of: undefined,
    limit: 12,
    offset: 0,
  },
  projectsBySlug: undefined,
  projectsCounter: 0,
  featuredProjectDataError: undefined,
  projectsDataError: undefined,
  projectDataError: undefined,
  projectsCountDataError: undefined,
  showSaleTime: false,
}

const projectSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    getProjectsData: (state, { payload }) => {
      /* saga trigger */
    },
    getProjectsDataSuccess: (state, { payload }) => {
      const { data, initFetching } = payload

      if (!Array.isArray(data)) return

      state.projects = initFetching ? data : state.projects.concat(data)
    },
    getProjectsDataError: (state, { payload }) => {
      state.projectsDataError = payload
    },

    getFeaturedProjectBannersData: (state, { payload }) => {
      Array.isArray(payload) && (state.featuredProjectBanners = payload)
    },
    addProjectsFilterElement: (state, { payload }) => {
      payload === 'allItems'
        ? (state.projectsFilterValue = initialState.projectsFilterValue)
        : (state.projectsFilterValue = {
            ...state.projectsFilterValue,
            ...payload,
          })
    },
    getProjectsCountData: (state, { payload }) => {
      state.projectsCounter = payload.count
    },
    getProjectsBySlugData: (state, { payload }) => {
      payload.id !== 0 && (state.projectsBySlug = payload)
    },
    errorGettingFeaturedProjectsData: (state, { payload }) => {
      state.featuredProjectDataError = payload
    },
    errorGettingProjectData: (state, { payload }) => {
      state.projectDataError = payload
    },
    errorGettingProjectsCountData: (state, { payload }) => {
      state.projectsCountDataError = payload
    },
  },
})

export const {
  getProjectsData,
  getProjectsDataSuccess,
  getProjectsDataError,
  getFeaturedProjectBannersData,
  addProjectsFilterElement,
  getProjectsBySlugData,
  getProjectsCountData,
  errorGettingFeaturedProjectsData,
  errorGettingProjectData,
  errorGettingProjectsCountData,
} = projectSlice.actions
export default projectSlice.reducer
