import { createSlice } from '@reduxjs/toolkit'

interface UserProps {
  userData: {
    id: number | null
    username: string | null
    firstName: string | null
    lastName: string | null
    avatarUrl: string | null
    email: string | null
    discordId: string | null
    twitterId: string | null
    wallet: string | null
    referralCode: null | { id: string }
  }
  paymentWallet: string
  userDataError: string | undefined
  paymentWalletDataError: string | undefined
}

const initialState: UserProps = {
  userData: {
    id: null,
    username: null,
    firstName: null,
    lastName: null,
    avatarUrl: null,
    email: null,
    discordId: null,
    twitterId: null,
    wallet: null,
    referralCode: null,
  },
  paymentWallet: '',
  userDataError: undefined,
  paymentWalletDataError: undefined,
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    clearUserData: (state, _data) => {
      state.userData = initialState.userData
    },
    getUserData: (state, { payload }) => {
      if (payload.id) {
        state.userData = { ...payload }
      }
    },
    setUserData: (state, { payload }) => {
      state.userData = { ...payload }
    },
    changeUserName: (state, { payload }) => {
      if (payload.id) {
        state.userData = { ...payload }
      }
    },
    getPaymentWallet: (state, { payload }) => {
      state.paymentWallet = payload.wallet
    },
    errorGettingUserData: (state, { payload }) => {
      state.userDataError = payload
    },
    errorGettingPaymentWalletData: (state, { payload }) => {
      state.paymentWalletDataError = payload
    },
  },
})

export const {
  clearUserData,
  getUserData,
  setUserData,
  changeUserName,
  getPaymentWallet,
  errorGettingUserData,
  errorGettingPaymentWalletData,
} = userSlice.actions
export default userSlice.reducer
